import './Element.css'

import Input from './Input/Input.jsx';
import Select from './Select/Select';


const Element = (props) => {

    

    switch(props.item.type){
        case 8:
            return(
                    <Select required={!props.item.isOptional} item={props.item} funcionParaElHjo={props.funcionParaElHjo}/>
                )
        default:
            return(
                    <Input setShowTextHelpModal={props.setShowTextHelpModal} required={!props.item.isOptional} item={props.item} funcionParaElHjo={props.funcionParaElHjo} />
                )
    }
}

export default Element;