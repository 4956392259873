import React from "react";
import "./Modal.css"

const Modal = (prop) => {
    const msgArr = prop.errorMsg.split("\n");

    return (
        <div className={prop.open ? "modal-bg" : "modal-bg2"}>
            <div className="modal-container">
                <p>
                    {msgArr.map((string, index) => <React.Fragment key={string + " " + index}>
                        {string}
                        <br />
                    </ React.Fragment>
                    )}
                </p>
                <button type="button" onClick={() => prop.setOpen(false)} style={{padding:"1rem"}}>Aceptar</button>
            </div>
        </div>
    )
}
export default Modal