import './Footer.css';

export default function Footer() {
    return (
        <footer class="colour-127">
            <div class="nav-footer footer-container">
                <div class="row">
                    <img src="https://www.mardelplata.gob.ar/assets/img/xlogo-footer.png.pagespeed.ic.PlHN5W2L2B.webp" class="logo-footer" alt="Logo Municipalidad de General Pueyrredón" />
                    <div class="span3">
                        <h3>ACCESOS DIRECTOS</h3>
                        <ul className='ulAccesos'>
                            <li><a href="https://www.mardelplata.gob.ar/EMVIAL">Vialidad y Alumbrado</a></li>
                            <li><a href="http://www.turismomardelplata.gov.ar/">Turismo</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/deportes">Deporte</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/serviciosurbanos">Servicios Urbanos</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/ARM">Agencia de Recaudación Municipal</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/hacienda">Hacienda</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/cultura">Cultura</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/educacion">Educación</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/Seguridad">Seguridad</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/produccion">Producción</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/salud">Salud</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/INSPECCIONGENERAL">Inspección General</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/Desarrollo_social">Desarrollo Social</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/obras">Obras y Planeamiento Urbano</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/Gobierno">Gobierno</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/privada">Privada</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/modernizacion/gde">Modernización</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/movilidadurbana">Movilidad Urbana</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/gobierno-digital">Gobierno Digital</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/comunidad">Asuntos de la Comunidad</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/relacionesinternacionales">Relaciones Internacionales</a></li>
                            <li><a href="https://www.mardelplata.gob.ar/defensadelconsumidor">Defensa del Consumidor</a></li>
                            <li><a href="http://www.osmgp.gov.ar/">Obras Sanitarias</a></li>
                            <li><a href="http://www.concejo.mdp.gob.ar/">Honorable Concejo Deliberante</a></li>
                        </ul>
                    </div>

                    <div class="span3 telefonos-utiles">
                        <h3>TELÉFONOS DE EMERGENCIA</h3>
                        <ul>
                            <li>
                                <a href="tel:107">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    &nbsp;
                                    107 | Emergencias Médicas
                                </a>
                            </li>
                            <li>
                                <a href="tel:145">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    &nbsp;
                                    145 | Trata de Personas
                                </a>
                            </li>
                            <li>
                                <a href="tel:911">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    &nbsp;
                                    911 | Policía
                                </a>
                            </li>
                            <li>
                                <a href="tel:102">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    &nbsp;
                                    102 | Niños en Riesgo
                                </a>
                            </li>
                            <li>
                                <a href="tel:147">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    &nbsp;
                                    147 | Atención al Vecino
                                </a>
                            </li>
                            <li>
                                <a href="tel:103">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    &nbsp;
                                    103 | Defensa Civil
                                </a>
                            </li>
                            <li>
                                <a href="tel:108">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    &nbsp;
                                    108 | Violencia de Género
                                </a>
                            </li>
                        </ul>
                        <div class="social">
                            <h3>CONTACTO</h3>
                            <ul>
                                <li><a class="social-fb" href="https://www.facebook.com/municipalidadmardelplata/"
                                    target="_blank"><i style={{ fontSize: "2rem" }} class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li><a class="social-tw" href="https://www.twitter.com/MGPmardelplata"
                                    target="_blank"><i style={{ fontSize: "2rem" }} class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a class="social-tw" href="https://www.youtube.com/user/munimardelplata"
                                    target="_blank"><i style={{ fontSize: "2rem" }} class="fa fa-youtube" aria-hidden="true"></i></a></li>
                                <li><a class="social-in" href="https://www.instagram.com/munimardelplata/"
                                    target="_blank"><i style={{ fontSize: "2rem" }} class="fa fa-instagram" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="span3">
                        
                    </div>
                    <div class="span12">
                        <div class="piepagina"></div>
                    </div>
                </div>
            </div>
        </footer>
    )
}