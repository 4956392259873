import './Form.css'
import '../../index.css'

import Element from './Element/Element.jsx';
import { useEffect, useMemo, useState } from 'react';

import { submitForm } from '../Services/FormData.js'
import Modal from '../Modal/Modal';


const Form = (props) => {

    const [formFields, SetFormFields] = useState([]);

    const handleFieldChange = (value, index) =>{
        SetFormFields((prev) => {
            let data = [...prev];
            
            if(typeof value == 'string'){
                data[index] = {
                    textResponse: value
                };
                SetFormFields(data);
            }else{
                data[index] = {
                    boolsResponse: value
                };
                SetFormFields(data);
            }
            return data;
        })
    }
    
    
    const handleSubmit = async (e) => {
        
        e.preventDefault()

        const activeElement = document.activeElement;
        
        if (activeElement.nodeName === "INPUT") {
            return;
        }
        
        const form = document.getElementById("formElement");

        if (!form.reportValidity()) {
            return;
        }
        
        const formFieldsCopy = formFields.filter((formField) => formField !== undefined);
        
        let rsp = await submitForm(formFieldsCopy, props.idForm);

        if(rsp?.error === false){
            window.location.replace('?id=0');
        }else{
            window.alert(rsp?.messege || "No fue posible enviar el formulario en este momento.");
        }

    }

    // useMemo asi solo corre una vez y no en todos los renders
    const initialShowTextHelpModal = useMemo(() => props?.elements?.some((element) => element.type === 0), []);

    const [showTextHelpModal, setShowTextHelpModal] = useState(initialShowTextHelpModal);

    const errorMsg = `Se permite escribir letras en español y la mayoría de los caracteres de un teclado en español.
    
    Si intenta escribir un caracter no válido, no se registrará.`;

    return(
        <section className="FormContainer">
            <form id='formElement' className='center' onSubmit={handleSubmit}>
                {props.elements.map((item) => {
                    return(
                        <div className='frmquestions' key={item.id}>
                            <Element setShowTextHelpModal={setShowTextHelpModal} item={item} key={item.id} funcionParaElHjo={handleFieldChange} />
                            <hr />    
                        </div>                        
                    );
                })}
                <div>
                    <button className='sendBtn' onClick={handleSubmit}>Enviar</button>
                </div>
            </form>
            <Modal open={showTextHelpModal} setOpen={setShowTextHelpModal} errorMsg={errorMsg} />
        </section>
    );


}

export default Form;