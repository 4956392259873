import { GET, POST } from './Fetch.js'


export async function getFormData(data){
    let url = "api/forms/getForm";
    let requestData = {
        id: parseInt(data)
    }

    let responseData = await GET(url, requestData);

    return responseData;
}


export async function submitForm(data, id){
    let url = "api/forms/submit";
    let requestData = {
        responses: data,
        idForm: parseInt(id)
    }

    let responseData = await POST(url, requestData);

    return responseData;
}