import './Sent.css'
import Logo from "../Assets/logo.png"

const Sent = () => {
  return (
    <div className="centrar">
      <div>
          <img src={Logo} alt="MGP Logo" className='logomgp'/>
      </div>
      <h2>Formulario enviado exitosamente.</h2>
      <br/>
      <h3>Su solicitud sera procesada en la brevedad.</h3>
    </div>
  )
}
export default Sent