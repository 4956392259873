import Header from './Header/Header.jsx';
import UserMenu from './UserMenu/UserMenu.jsx';
import Form from './Form/Form.jsx';
import Spinner from './Spinner/Spinner.jsx';
import NotFound from './NotFound/NotFound.jsx';
import Sent from './Sent/Sent.jsx';
import { useEffect, useState } from 'react';

import { getFormData } from './Services/FormData.js';

import { validPhone } from '../Helpers/FormValidations.js';
import Footer from './Footer/Footer.jsx';

function App() {

const [formData, setFormData] = useState({});
const [isLoadding, setIsLoadding] = useState(true);
const [onError, setOnError] = useState(false);
const [isSent, setIsSent] = useState(false);
const serachParams = new URLSearchParams(document.location.search);


const loadFormData = async () => {
  let id = serachParams.get('id');

  if(id == 0){
    setIsSent(true);
  }else{
    let rsp = await getFormData(id);
  
    if(rsp?.error === false){
      setFormData(rsp?.data);
      setIsLoadding(false);
    }
    else
    {
      setOnError(true);
      setIsLoadding(false);
    }
  }




}

useEffect(() => {
  loadFormData();
}, [])

  if(isSent){
    return(
      <Sent />
    );
  }else{
      if(isLoadding){
        return(
          <Spinner />
        );
      }else{
        return (
              !onError ?
              <>
                <Header />
                <UserMenu tittle={formData?.title.title} caption={formData?.title.caption}/>
                <Form elements={formData?.elements} idForm={serachParams.get('id')}/>
                <Footer />
              </>
              :
              <NotFound />
        );
    }
  }
}

export default App;
