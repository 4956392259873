import { Fragment, useEffect, useRef, useState } from "react"
import Modal from "../../../Modal/Modal";

const Input = (props) => {
    const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
    let idnum = props.item.id;

    const handledBut = (e) => {
        e.preventDefault();
        props.funcionParaElHjo(e.target.value, props.item.id);
    }

    const handledBut2 = (e) => {
        e.preventDefault()
        const valor = e.target.value
        const index = props.item.id

        // console.log({ valor, index });
        props.funcionParaElHjo(valor, index)
    }

    const validateText = (e) => {
        e.target.value = e.target.value.replace(/[^0-9a-zA-ZáéíóúñÁÉÍÓÚÑüÜ\s+\-/*º\\!|"@·#$~%&()=?¿'¡`^[\]´¨{}_,;:<>]+$/, "");
    }

    const validatePhone = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]+$/, "").slice(0, 16);
    }


    const test = (e) => {
        e.preventDefault(e);

        var $tests = document.getElementsByClassName(`chx${props.item.id}`);


        let tmp = [];
        for (let i = 0; i < $tests.length; i++) {
            tmp[i] = $tests[i].checked ? true : false;
        }

        props.funcionParaElHjo(tmp, props.item.id);
    }

    useEffect(() => {
        if (props.item.type === 10)
            props.funcionParaElHjo("", props.item.id)
        else if (props.item.type === 9)
            props.funcionParaElHjo(props.item.options.map(() => false), props.item.id)
        else
            props.funcionParaElHjo("", props.item.id)
    }, []);

    switch (props.item.type) {
        case 0:
            return (
                <Fragment key={props.item.id}>

                    <label className="formLabel">
                        <strong>{props.item.title}</strong>
                        <button className='infoBtn' type="button" onClick={() => props.setShowTextHelpModal(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-info-circle inf-svg" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" /> </svg>
                        </button>
                    </label>
                    <input required={!props.item.isOptional} type="text" maxLength={254} className="formInput" name={props.index} id={props.item.id} onBlur={handledBut} onChange={validateText} />
                </Fragment>
            )

        case 1:
            return (<Fragment key={props.item.id}>
                <label className="formLabel"><strong>{props.item.title}</strong></label>
                <input required={!props.item.isOptional} type="number" max="999999999999" className="formInput" name={props.index} id={props.item.id} onBlur={handledBut} />
            </Fragment>)
        case 2:
            return (<Fragment key={props.item.id}>
                <label className="formLabel "><strong>{props.item.title}</strong></label>
                <label className="formHelperLabel">(Ingrese SOLO los numeros del tel&eacute;fono/celular)</label>
                <input required={!props.item.isOptional} type="text" pattern="[0-9]{0,16}" className="formInput no-arrow" name={props.index} id={props.item.id} onBlur={handledBut} onChange={validatePhone} min='0' />

            </Fragment>)
        case 3:
            return (<Fragment key={props.item.id}>
                <label className="formLabel"><strong>{props.item.title}</strong></label>
                <input required={!props.item.isOptional} type="email" maxLength={254} className="formInput" name={props.index} id={props.item.id} onBlur={handledBut} />

            </Fragment>)
        case 4:
            return (<Fragment key={props.item.id}>
                <label className="formLabel"><strong>{props.item.title}</strong></label>
                <input required={!props.item.isOptional} type="range" className="formInput setpointer" name={props.index} id={props.item.id} onBlur={handledBut} />

            </Fragment>)
        case 5:
            return (<Fragment key={props.item.id}>
                <label className="formLabel"><strong>{props.item.title}</strong></label>
                <input required={!props.item.isOptional} type="time" className="formInput" name={props.index} id={props.item.id} onBlur={handledBut} />

            </Fragment>)
        case 6:
            return (<Fragment key={props.item.id}>
                <label className="formLabel"><strong>{props.item.title}</strong></label>
                <input required={!props.item.isOptional} type="date" className="formInput" name={props.index} id={props.item.id} onBlur={handledBut} />
            </Fragment>)
        case 7:
            return (<Fragment key={props.item.id}>
                <label className="formLabel"><strong>{props.item.title}</strong></label>
                <input required={!props.item.isOptional} type="datetime-local" className="formInput" name={props.index} id={props.item.id} onBlur={handledBut} />

            </Fragment>)
        case 9:
            return (<Fragment key={props.item.id}>
                <label className="formLabel"><strong>{props.item.title}</strong> (Seleccione ninguna o varias)</label>
                <fieldset id={props.item.id} onBlur={test}>{/*Falta cargar en el array todas las opciones activas, y que no se muestra la ultima en desactivarse*/}
                    {props.item.options.map((option, index) =>
                        <label className="formLabel setpointer checkDiv" key={{ idnum } + letters[index]}>
                            <input type="checkbox" value={option.subElement} className={"setpointer" + ' ' + `chx${props.item.id}`} name={props.item.title} id={props.index + 'chx' + props.item.id} />
                            {option.subElement}
                        </label>
                    )}
                </fieldset>

            </Fragment>)
        case 10:
            return (<Fragment key={props.item.id}>
                <label className="formLabel"><strong>{props.item.title}</strong> (Seleccione una opci&oacute;n)</label>
                <fieldset id={props.item.id} >
                    {props.item.options.map((option, index) =>
                        <label className="formLabel RadioDiv" key={{ idnum } + letters[index]}>
                            <input required={!props.item.isOptional} type="radio" value={option.subElement} name={props.item.title} className="radioElements" id={props.item.id} onBlur={handledBut2} />
                            {option.subElement}
                        </label>
                    )}
                </fieldset>
            </Fragment>)
        default:

            <></>


    }



}

export default Input; 