import './NotFound.css'
import Logo from "../Assets/logo.png"

const NotFound = () => {
  return (
    <div className="centrar">
      <div>
          <img src={Logo} alt="MGP Logo" className='logomgp'/>
      </div>
      <h2>Error 404.</h2>
      <h2>Formulario no encontrado, intente mas tarde.</h2>
    </div>
  )
}
export default NotFound