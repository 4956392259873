import React from "react";
import "./UserMenu.css"


const UserMenu = (props) => {
    
    return(
        <menu className="UserMenu">
            <div className="titlecontainer">
                <h2 className="UserMenuTittle">{props?.tittle || "Formulario"}</h2>
                <div className="subtitle-container">
                <h3 className="UserMenuCaption">{props?.caption || ""}</h3>
                </div>
            </div>
        </menu>
    );
    
}

export default UserMenu;