import './Spinner.css'
import SpinnerGif from './cargando.gif'

const Spinner = () => {

    return(
        <section className="spinnerSection">
            <img src={SpinnerGif} className="spinnerGif"/>
        </section>
    );
}

export default Spinner;