import { useEffect } from "react"

const Select = (props) => {
  const letters = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z']
  let idnum= props.item.id

  const handledBut = (e) => {
    e.preventDefault()
    const valor = e.target.value
    const index = props.item.id
    props.funcionParaElHjo(valor,index)
  }

  useEffect(() => {
    props.funcionParaElHjo(props.item.options[0].subElement, props.item.id)
  }, []);

  return (
    <>
      <label className="formLabel "><strong>{props.item.title}</strong></label>
      <select className="formSelectInput" name={props.index}  id={props.item.id} onBlur={handledBut}>
        {props.item.options.map((option,index)=>
          <option key={{idnum}+letters[index]} value={option.subElement}>{option.subElement}</option>
        )}
      </select>
    </>
  )
}
export default Select