import React from "react";

import './Header.css'
import Logo from "../Assets/logo.png"

const Header = () => {
    return(
        <header className="MGPHeader">
            <img src={Logo} lat="Municipalidad de General Pueyrredon"></img>
        </header>
    );
}


export default Header;